<template>
  <v-container fluid class="mb-16">
    <v-card height="100%">
      <v-row>
        <v-card-text style="background: white; height: 100%">
          <v-row class="ml-7" style="width: 96%">
            <v-col>
              <template>
                <v-toolbar elevation="2" style="background: #f5f5f5">
                  <v-toolbar-title class="mt-2 mb-2 ml-1">
                    Product Stock Report
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <div class="pr-5 pb-5 pt-5">
                    <v-text-field
                      v-model="searchseller"
                      append-icon="mdi-magnify"
                      label="Search"
                      filled
                      rounded
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                  ​
                  <div>
                    <v-btn text @click="print" class="ml-10">
                      <v-icon large color="black">mdi-printer </v-icon>
                    </v-btn>
                  </div>
                  <!-- <v-switch
                      v-model="singleExpand"
                      label="Single expand"
                      class="mt-5"
                    ></v-switch>  v-if="item.Brand_name != 'Individual' "-->
                </v-toolbar>
                ​
                <v-data-table
                  :headers="SellerHeader"
                  :items="specifications"
                  :search="searchseller"
                  class="elevation-1"
                  style="background: #f5f5f5 elevation-1"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  hide-default-footer
                  disable-pagination
                >
                  <!-- <template v-slot:item.actions="{ item }">
                    <v-btn
                      shaped
                      icon
                      @click="viewSpecification(item)"
                      color="green"
                    >
                      <v-icon dark> mdi-pencil</v-icon>
                    </v-btn>
                  </template> -->
                </v-data-table>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-row>
    </v-card>
    ​
  </v-container>
</template>
​
​
​
<script>
export default {
  data: () => ({
    loading: true,
    searchseller: "",
    SellerHeader: [
      //   {
      //   text: "Date",
      //   value: "seller_name",
      //   sortable: false,
      // },
      {
        text: "SKU",
        value: "product_sku",
        sortable: false,
      },
      //{ text: "Barcode", value: "product_barcode", sortable: false },
      { text: "Product Name", value: "product_name", sortable: false },
      { text: "Brand ", value: "product_brand", sortable: false },
      {
        text: "Available Quantity",
        value: "available_quantity",
        sortable: false,
      },
      { text: "Out Quantity", value: "debit_sum", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    tickets: [],
    contact: {},
    specifications: [],
    sellerPdetails: {},
      user: {},
  }),
  methods: {
    //This api is for demo testing with child site data
    initialize() {
      axios
        .get(`merchant/get_merchant_stock_report/${this.user.user_id}`)
        .then((response) => {
          let beforeCustomization = [];
          beforeCustomization = response.data.data[1];
          beforeCustomization.forEach((element) => {
            element.forEach((childElement) => {
              this.specifications.push(childElement);
            });
          });
          // console.log(this.specifications);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // This Link will be Change for link change
    print() {
      // let routeData = this.$router.resolve({
      //   name: `site/get_productstockreport_pdf/`,
      // });
      // routeData.href =
      //   axios.defaults.baseURL + `site/get_productstockreport_pdf/`;
      // window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.user && this.user.user_id) {
      this.initialize();
    }
  },
};
</script>
​
​
​
<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>